<template>
  <v-container class="fill-height" >
    <v-row class="text-center"   align="center" justify="center" >
      <v-col cols="12" class="text-h3">
        You've been blocked!
      </v-col>
      <v-col cols="12">
        <img src="https://vblimages.blob.core.windows.net/images/Blocking2.gif" alt="Blocked!">
      </v-col>
      <v-col cols="12" class="subheading">
        Well techincally you weren't blocked... <b>the page you are looking for can't be found</b><br>
        But there is not volleyball term for page not found 😉
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    user: function (n, o) {
      const goto = this.$route.query.returnUrl
      if (n && goto) {
        this.$router.push(goto)
      }
    }
  }
}
</script>

<style>

</style>
